const zoneColumns = [
  {
    key: 'DateStats',
    label: 'Date',
    show: true,
    headerTitle: 'Date',
    sortable: true,
    thStyle: 'width:90px',
    summed: false,
  },
  {
    key: 'AdvertiserId',
    label: 'Advertiser ID',
    show: false,
    headerTitle: 'Advertiser ID',
    sortable: true,
    thStyle: 'width:110px',
    tdClass: 'text-right',
    summed: false,
  },
  {
    key: 'AdvertiserName',
    label: 'Advertiser Name',
    show: true,
    headerTitle: 'Advertiser Name',
    sortable: false,
    summed: false,
  },
  {
    key: 'VideoadId',
    label: 'Video Ad ID',
    show: false,
    headerTitle: 'Video Ad ID',
    sortable: true,
    thStyle: 'width:110px',
    tdClass: 'text-right',
    summed: false,
  },
  {
    key: 'VideoadName',
    label: 'Video Ad Name',
    show: true,
    headerTitle: 'Video Ad Name',
    sortable: false,
    summed: false,
  },
  {
    key: 'NativeadId',
    label: 'Native Ad ID',
    show: false,
    headerTitle: 'Native Ad ID',
    sortable: true,
    thStyle: 'width:110px',
    tdClass: 'text-right',
    summed: false,
  },
  {
    key: 'NativeadName',
    label: 'Native Ad Name',
    show: true,
    headerTitle: 'Native Ad Name',
    sortable: false,
    summed: false,
  },
  {
    key: 'RemotertbzoneId',
    label: 'Rmt. RTB Zone ID',
    show: false,
    headerTitle: 'Rmt. RTB Zone ID',
    sortable: true,
    thStyle: 'width:140px',
    tdClass: 'text-right',
    summed: false,
  },
  {
    key: 'RemotertbzoneName',
    label: 'Rmt. RTB Zone Name',
    show: true,
    headerTitle: 'Rmt. RTB Zone Name',
    sortable: false,
    summed: false,
  },
  {
    key: 'RemotevideozoneId',
    label: 'Rmt. Video Zone ID',
    show: false,
    headerTitle: 'Rmt. Video Zone ID',
    sortable: true,
    thStyle: 'width:150px',
    tdClass: 'text-right',
    summed: false,
  },
  {
    key: 'RemotevideozoneName',
    label: 'Rmt. Video Zone Name',
    show: true,
    headerTitle: 'Rmt. Video Zone Name',
    sortable: false,
    summed: false,
  },
  {
    key: 'PublisherId',
    label: 'Publisher ID',
    show: false,
    headerTitle: 'Publisher ID',
    sortable: true,
    thStyle: 'width:110px',
    tdClass: 'text-right',
    summed: false,
  },
  {
    key: 'PublisherName',
    label: 'Publisher Name',
    show: true,
    headerTitle: 'Publisher Name',
    sortable: false,
    summed: false,
  },
  {
    key: 'VideozoneId',
    label: 'Video Zone ID',
    show: false,
    headerTitle: 'Video Zone ID',
    sortable: true,
    thStyle: 'width:120px',
    tdClass: 'text-right',
    summed: false,
  },
  {
    key: 'VideozoneName',
    label: 'Video Zone Name',
    show: true,
    headerTitle: 'Video Zone Name',
    sortable: false,
    summed: false,
  },
  {
    key: 'RtbzoneId',
    label: 'RTB Zone ID',
    show: false,
    headerTitle: 'RTB Zone ID',
    sortable: true,
    thStyle: 'width:105px',
    tdClass: 'text-right',
    summed: false,
  },
  {
    key: 'RtbzoneName',
    label: 'RTB Zone Name',
    show: true,
    headerTitle: 'RTB Zone Name',
    sortable: false,
    summed: false,
  },
  {
    key: 'CreativeId',
    label: 'Creative ID',
    show: false,
    headerTitle: 'Creative ID',
    sortable: true,
    thStyle: 'width:100px',
    tdClass: 'text-right',
    summed: false,
  },
  {
    key: 'CreativeName',
    label: 'Creative Name',
    show: true,
    headerTitle: 'Creative Name',
    sortable: false,
    summed: false,
  },
  {
    key: 'OverlayId',
    label: 'Overlay ID',
    show: false,
    headerTitle: 'Overlay ID',
    sortable: true,
    thStyle: 'width:100px',
    tdClass: 'text-right',
    summed: false,
  },
  {
    key: 'OverlayName',
    label: 'Overlay Name',
    show: true,
    headerTitle: 'Overlay Name',
    sortable: false,
    summed: false,
  },
  {
    key: 'CompanionId',
    label: 'Companion ID',
    show: false,
    headerTitle: 'Companion ID',
    sortable: true,
    thStyle: 'width:120px',
    tdClass: 'text-right',
    summed: false,
  },
  {
    key: 'CompanionName',
    label: 'Companion Name',
    show: true,
    headerTitle: 'Companion Name',
    sortable: false,
    summed: false,
  },
  {
    key: 'IconId',
    label: 'Icon ID',
    show: false,
    headerTitle: 'Icon ID',
    sortable: true,
    thStyle: 'width:80px',
    tdClass: 'text-right',
    summed: false,
  },
  {
    key: 'IconName',
    label: 'Icon Name',
    show: true,
    headerTitle: 'Icon Name',
    sortable: false,
    summed: false,
  },
  {
    key: 'CC',
    label: 'Country',
    show: false,
    headerTitle: 'Country',
    sortable: true,
    thStyle: 'width: 85px',
    class: 'text-center',
    summed: false,
  },
  {
    key: 'OsFamily',
    label: 'OS',
    show: false,
    headerTitle: 'OS',
    sortable: true,
    thStyle: 'width:100px',
    summed: false,
  },
  {
    key: 'BrowserFamily',
    label: 'Browser',
    show: false,
    headerTitle: 'Browser',
    sortable: true,
    thStyle: 'width:130px',
    summed: false,
  },
  {
    key: 'CreativeImpressions',
    label: 'Creative Imp.',
    show: true,
    headerTitle: 'Creative Impressions',
    sortable: true,
    thClass: 'text-center',
    tdClass: 'text-right',
    formatter: '$formatNumber',
    thStyle: 'width:120px',
    summed: true,
  },
  {
    key: 'CreativeStart',
    label: 'Creative Start',
    show: false,
    headerTitle: 'Creative Start',
    sortable: true,
    thClass: 'text-center',
    tdClass: 'text-right',
    formatter: '$formatNumber',
    thStyle: 'width:120px',
    summed: true,
  },
  {
    key: 'CreativeComplete',
    label: 'Creative Complete',
    show: false,
    headerTitle: 'Creative Complete',
    sortable: true,
    thClass: 'text-center',
    tdClass: 'text-right',
    formatter: '$formatNumber',
    thStyle: 'width:150px',
    summed: true,
  },
  {
    key: 'CreativeSkip',
    label: 'Creative Skip',
    show: false,
    headerTitle: 'Creative Skip',
    sortable: true,
    thClass: 'text-center',
    tdClass: 'text-right',
    formatter: '$formatNumber',
    thStyle: 'width:120px',
    summed: true,
  },
  {
    key: 'CreativeClicks',
    label: 'Creative Clicks',
    show: false,
    headerTitle: 'Creative Clicks',
    sortable: true,
    thClass: 'text-center',
    tdClass: 'text-right',
    formatter: '$formatNumber',
    thStyle: 'width:120px',
    summed: true,
  },
  {
    key: 'CreativeNetClicks',
    label: 'Creative Net Clicks',
    show: false,
    headerTitle: 'Creative Net Clicks',
    sortable: true,
    thClass: 'text-center',
    tdClass: 'text-right',
    formatter: '$formatNumber',
    thStyle: 'width:150px',
    summed: true,
  },
  {
    key: 'OverlayImpressions',
    label: 'Overlay Imp.',
    show: true,
    headerTitle: 'Overlay Impressions',
    thClass: 'text-center',
    tdClass: 'text-right',
    formatter: '$formatNumber',
    thStyle: 'width:120px',
    sortable: true,
    summed: true,
  },
  {
    key: 'OverlayStart',
    label: 'Overlay Start',
    show: false,
    headerTitle: 'Overlay Start',
    sortable: true,
    thClass: 'text-center',
    tdClass: 'text-right',
    formatter: '$formatNumber',
    thStyle: 'width:120px',
    summed: true,
  },
  {
    key: 'OverlayComplete',
    label: 'Overlay Complete',
    show: false,
    headerTitle: 'Overlay Complete',
    sortable: true,
    thClass: 'text-center',
    tdClass: 'text-right',
    formatter: '$formatNumber',
    thStyle: 'width:140px',
    summed: true,
  },
  {
    key: 'OverlaySkip',
    label: 'Overlay Skip',
    show: false,
    headerTitle: 'Overlay Skip',
    sortable: true,
    thClass: 'text-center',
    tdClass: 'text-right',
    formatter: '$formatNumber',
    thStyle: 'width:120px',
    summed: true,
  },
  {
    key: 'OverlayClicks',
    label: 'Overlay Clicks',
    show: false,
    headerTitle: 'Overlay Clicks',
    thClass: 'text-center',
    tdClass: 'text-right',
    formatter: '$formatNumber',
    thStyle: 'width:120px',
    sortable: true,
    summed: true,
  },
  {
    key: 'OverlayNetClicks',
    label: 'Overlay Net Clicks',
    show: false,
    headerTitle: 'Overlay Net Clicks',
    sortable: true,
    thClass: 'text-center',
    tdClass: 'text-right',
    formatter: '$formatNumber',
    thStyle: 'width:140px',
    summed: true,
  },
  {
    key: 'CompanionImpressions',
    label: 'Companion Imp.',
    show: true,
    headerTitle: 'Companion Impressions',
    sortable: true,
    thClass: 'text-center',
    tdClass: 'text-right',
    formatter: '$formatNumber',
    thStyle: 'width:140px',
    summed: true,
  },
  {
    key: 'CompanionClicks',
    label: 'Companion Clicks',
    show: false,
    headerTitle: 'Companion Clicks',
    sortable: true,
    thClass: 'text-center',
    tdClass: 'text-right',
    formatter: '$formatNumber',
    thStyle: 'width:140px',
    summed: true,
  },
  {
    key: 'IconImpressions',
    label: 'Icon Imp.',
    show: true,
    headerTitle: 'Icon Impressions',
    sortable: true,
    thClass: 'text-center',
    tdClass: 'text-right',
    formatter: '$formatNumber',
    thStyle: 'width:90px',
    summed: true,
  },
  {
    key: 'IconClicks',
    label: 'Icon Clicks',
    show: false,
    headerTitle: 'Icon Clicks',
    sortable: true,
    thClass: 'text-center',
    tdClass: 'text-right',
    formatter: '$formatNumber',
    thStyle: 'width:100px',
    summed: true,
  },
]
export default zoneColumns
